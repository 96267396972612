import { ColDef, IRowNode, ProcessDataFromClipboardParams } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Check, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { parse } from 'date-fns';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { numberValueFormatterAgGrid } from '../../../agGrid/formatter';
import { clientSideTableDefaultProps } from '../../../agGrid/gridDefaults';
import { ensureEmptyRowAtBottom, resetGrid } from '../../../agGrid/gridUtils';
import { parseFromClipboard } from '../../../agGrid/parseFromClipboard';
import { ActionButton } from '../../../components/ActionButton';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { DeleteButtonCellRenderer } from '../../../components/agGrid/cellRenderers/DeleteButtonCellRenderer';
import { buildValidationProps, rowIsEmpty } from '../../../components/agGrid/validatationFunctions';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { errorsFromSAPtoMessage } from '../../../core/errorhandling';
import { getErrorMessage } from '../../../core/errors';
import { t } from '../../../core/i18n/i18n';
import { preferredDateFormat } from '../../../core/i18n/l10n';
import { strictlyParseFloat } from '../../../core/number';
import { validateMaterialNumber } from '../../../core/validation/filterValidation';
import { validateDateFormat, validateForFloat } from '../../../core/validation/validationHelper';
import { KpiBucketType, WriteKpiData } from '../../../domain/demandValidation/model';
import {
  MaterialType,
  saveValidatedDemandBatch,
  ValidatedDemandBatchErrorMessages,
} from '../../../domain/demandValidation/saveValidatedDemand';
import { CustomerEntry } from '../../../domain/globalSelection/model';

import { ListUploadPeriodType, ListUploadPeriodTypeRenderer } from './ListUploadPeriodTypeRenderer';

type Props = {
  customer: CustomerEntry;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
  materialType: MaterialType;
};

export function ListUploadTableModal(props: Props) {
  const gridRef = useRef<AgGridReact | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<ValidatedDemandBatchErrorMessages>({});
  const [parsingErrors, setParsingErrors] = useState<string[]>([]);
  const snackbar = useSnackbar();

  const handleCloseModal = (): void => {
    props.onClose();
    setErrorMessages({});
    setParsingErrors([]);
    setLoading(false);
  };

  function getDemandDataFromList() {
    const demandData: WriteKpiData[] = [];
    const invalidRows: IRowNode[] = [];

    gridRef.current?.api.getModel().forEachNode((row) => {
      if (rowIsEmpty(row)) {
        // skip emtpy rows
        return;
      }

      try {
        demandData.push(validatedDemandFromRow(row, props.customer.customerNumber));
      } catch {
        invalidRows.push(row);
      }
    });

    return {
      demandData,
      invalidRows,
    };
  }

  function validatedDemandFromRow(rowNode: IRowNode, customerNumber: string): WriteKpiData {
    const { material, dateString, forecast, periodType } = rowNode.data;

    if (!material || typeof material != 'string') {
      throw new Error(`Could not parse field 'material' as string.`);
    }

    if (!periodType || typeof periodType != 'string') {
      throw new Error(`Could not parse field 'periodType' as string.`);
    }

    if (!dateString || typeof dateString != 'string') {
      throw new Error(`Could not parse field 'dateString' as string.`);
    }

    const date = parse(dateString, preferredDateFormat, new Date());

    const value = strictlyParseFloat(forecast);
    if (Number.isNaN(value)) {
      throw new Error(`Cound not parse field 'forecast' as number.`);
    }

    return {
      ids: rowNode.id ? [rowNode.id] : [],
      customerNumber,
      materialNumber: material,
      kpiEntries: [
        {
          idx: rowNode.id ? parseInt(rowNode.id) : undefined,
          fromDate: format(date, 'yyyy-MM-dd'),
          bucketType: (periodType === 'month' ? 'MONTH' : 'WEEK') as KpiBucketType,
          validatedForecast: value,
        },
      ],
    };
  }

  async function saveUpload(dryRun = false) {
    setLoading(true);
    const { demandData, invalidRows } = getDemandDataFromList();
    setParsingErrors(invalidRows.map((row) => row.id).filter(Boolean) as string[]);

    try {
      const result = await saveValidatedDemandBatch(demandData, dryRun, props.materialType);
      setErrorMessages(result.errorMessages);
      const totalUnsavedRows = invalidRows.length + (demandData.length - result.savedCount);

      if (result.savedCount > 0 && !dryRun) {
        props.onSave();
      }

      const i18nActionKey = dryRun ? 'validate' : 'save';

      if (totalUnsavedRows == 0) {
        snackbar.enqueueSnackbar(
          t(`validation_of_demand.upload_modal.${i18nActionKey}.success`, {}),
          {
            variant: 'success',
          },
        );

        if (!dryRun) {
          handleCloseModal();
        }
      } else {
        snackbar.enqueueSnackbar(
          t(`validation_of_demand.upload_modal.${i18nActionKey}.invalid_rows`, {
            count: totalUnsavedRows,
          }),
          { variant: 'error' },
        );
      }
    } catch (e) {
      const errorMessage = t(`validation_of_demand.upload_modal.upload_failed`, {
        reason: getErrorMessage(e),
      });

      snackbar.enqueueSnackbar(errorMessage, { variant: 'error' });
    }

    setLoading(false);
  }

  const materialValidationFunction = useCallback(
    (value: string, node: IRowNode) => {
      const localValidation =
        value && props.materialType == 'schaeffler' ? validateMaterialNumber(value) : null;

      const parsingError = node.id && parsingErrors.includes(node.id);

      if (localValidation) {
        return localValidation.join('\n');
      } else if (parsingError) {
        return t('validation_of_demand.upload_modal.error.incomplete_row', {});
      } else {
        const errorMessagesForEntry = node.id ? errorMessages[node.id] : undefined;
        return errorMessagesForEntry
          ?.map((resultMessage) => errorsFromSAPtoMessage(resultMessage))
          .join('\n');
      }
    },
    [errorMessages, parsingErrors, props.materialType],
  );

  function periodTypeFromString(columnData: string) {
    if (
      columnData.toUpperCase() ==
        t(`validation_of_demand.upload_modal.paste.month`, {}).toUpperCase() ||
      columnData.toUpperCase() === 'M'
    ) {
      return 'month';
    }

    if (
      columnData.toUpperCase() ==
        t(`validation_of_demand.upload_modal.paste.week`, {}).toUpperCase() ||
      columnData.toUpperCase() === 'W'
    ) {
      return 'week';
    }

    return undefined;
  }

  const columnDefs = useMemo(() => {
    return [
      {
        headerName:
          props.materialType === 'schaeffler'
            ? t('validation_of_demand.upload_modal.material_number', {})
            : t('validation_of_demand.upload_modal.customer_material_number', {}),
        field: 'material',
        editable: true,
        ...buildValidationProps(materialValidationFunction, true, 'white'),
      },
      {
        headerName: t('validation_of_demand.upload_modal.list.date_from', {}),
        editable: true,
        field: 'dateString',
        ...buildValidationProps(validateDateFormat),
      },
      {
        headerName: t('validation_of_demand.upload_modal.list.forecast', {}),
        editable: true,
        field: 'forecast',
        valueFormatter: numberValueFormatterAgGrid,
        ...buildValidationProps(validateForFloat),
      },
      {
        headerName: t('validation_of_demand.upload_modal.list.period_type', {}),
        field: 'periodType',
        editable: true,
        cellRenderer: ListUploadPeriodTypeRenderer,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          cellRenderer: ListUploadPeriodTypeRenderer,
          values: ['week', 'month'] as ListUploadPeriodType[],
        },
      },
      { cellRenderer: 'deleteButton', minWidth: 68, maxWidth: 68 },
    ] as ColDef[];
  }, [materialValidationFunction, props.materialType]);

  return (
    <Modal open={props.open} onClose={handleCloseModal} maxWidth="lg" fullWidth>
      <LoadingSpinnerModal open={loading} />

      <Modal.Headline
        text={`${t('validation_of_demand.upload_modal.list.title', {})} - ${
          props.customer.customerName
        }`}
        onClose={handleCloseModal}
      >
        <ActionButton
          color={'secondary'}
          name={t('button.reset', {})}
          onClick={() => resetGrid(gridRef)}
        >
          <Close />
        </ActionButton>
        <ActionButton
          color={'secondary'}
          name={t('button.validate', {})}
          onClick={() => saveUpload(true)}
        >
          <Check />
        </ActionButton>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => {
            saveUpload();
          }}
        >
          {t('button.save', {})}
        </Button>
      </Modal.Headline>

      <Modal.SubHeadline />

      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledGridSection>
            <AgGridReactStyled
              {...clientSideTableDefaultProps}
              ref={gridRef}
              domLayout={'autoHeight'}
              defaultColDef={{
                suppressMenu: true,
              }}
              onCellEditingStopped={() => {
                ensureEmptyRowAtBottom(gridRef);
              }}
              onRowDataUpdated={() => {
                ensureEmptyRowAtBottom(gridRef);
              }}
              suppressRowHoverHighlight={true}
              tooltipShowDelay={500}
              components={{
                deleteButton: DeleteButtonCellRenderer,
              }}
              onGridReady={(e) => {
                e.api.applyTransaction({ add: [{}] });
                e.api.sizeColumnsToFit();
              }}
              processDataFromClipboard={(params: ProcessDataFromClipboardParams) =>
                parseFromClipboard(gridRef, params.data, (fieldName, columnData) => {
                  if (fieldName == 'periodType') {
                    return periodTypeFromString(columnData) ?? columnData;
                  }
                  return columnData;
                })
              }
              columnDefs={columnDefs}
            ></AgGridReactStyled>
          </StyledGridSection>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// We need to adapt the css of the ag grid rich selection, to fix the size. Otherwise, the pop up is to big.
const AgGridReactStyled = styled(AgGridReact)`
  div.ag-rich-select .ag-rich-select-list {
    width: 100%;
    min-width: 200px;
    height: 100% !important;
  }
`;
