import { ColDef, IRowNode } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Check, Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { numberValueFormatterAgGrid } from '../../../agGrid/formatter';
import { clientSideTableDefaultProps } from '../../../agGrid/gridDefaults';
import { addRowsFromClipboard, ensureEmptyRowAtBottom, resetGrid } from '../../../agGrid/gridUtils';
import { ActionButton } from '../../../components/ActionButton';
import { StyledGridSection } from '../../../components/StyledGridSection';
import { DeleteButtonCellRenderer } from '../../../components/agGrid/cellRenderers/DeleteButtonCellRenderer';
import { buildValidationProps, rowIsEmpty } from '../../../components/agGrid/validatationFunctions';
import { LoadingSpinnerModal } from '../../../components/loadingSpinner/LoadingSpinnerDialog';
import Modal from '../../../components/modal/Modal';
import { errorsFromSAPtoMessage } from '../../../core/errorhandling';
import { getErrorMessage } from '../../../core/errors';
import { t } from '../../../core/i18n/i18n';
import { formatDate, preferredDateFormatWithoutDay } from '../../../core/i18n/l10n';
import { strictlyParseFloat } from '../../../core/number';
import { validateMaterialNumber } from '../../../core/validation/filterValidation';
import { validateForFloat } from '../../../core/validation/validationHelper';
import {
  KpiBucket,
  KpiDateRanges,
  WriteKpiData,
  WriteKpiEntry,
} from '../../../domain/demandValidation/model';
import {
  MaterialType,
  saveValidatedDemandBatch,
  ValidatedDemandBatchErrorMessages,
} from '../../../domain/demandValidation/saveValidatedDemand';
import useKpiBuckets from '../../../domain/demandValidation/useKpiBuckets';
import { CustomerEntry } from '../../../domain/globalSelection/model';
import { demandValidationPartialWeekColor } from '../../../styles/colors';
import { getWeekHeader } from '../DemandValidationKpiHeader';

type Props = {
  customer: CustomerEntry;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
  dateRange: KpiDateRanges;
  materialType: MaterialType;
};

export function GridUploadTableModal(props: Props) {
  const gridRef = useRef<AgGridReact | null>(null);
  const [errorMessages, setErrorMessages] = useState<ValidatedDemandBatchErrorMessages>({});
  const [parsingErrors, setParsingErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const snackbar = useSnackbar();
  const { data: kpiBuckets } = useKpiBuckets(props.dateRange);
  const handleCloseModal = (): void => {
    props.onClose();
    setErrorMessages({});
    setParsingErrors([]);
    setLoading(false);
  };

  function getDemandDataFromGrid() {
    const demandData: WriteKpiData[] = [];

    const invalidRows: IRowNode[] = [];

    gridRef.current?.api.getModel().forEachNode((row) => {
      if (rowIsEmpty(row)) {
        // skip emtpy rows
        return;
      }
      try {
        demandData.push(
          validatedDemandFromRow({
            rowNode: row,
            customerNumber: props.customer.customerNumber,
            kpiBuckets: kpiBuckets || [],
          }),
        );
      } catch {
        invalidRows.push(row);
      }
    });

    return {
      demandData,
      invalidRows,
    };
  }

  async function saveUpload(dryRun = false) {
    setLoading(true);
    const { demandData, invalidRows } = getDemandDataFromGrid();

    if (demandData.every((row) => row.kpiEntries.length === 0)) {
      setLoading(false);
      snackbar.enqueueSnackbar(t('validation_of_demand.error.no_data', {}), {
        variant: 'error',
      });
      return;
    }

    setParsingErrors(invalidRows.map((row) => row.id).filter(Boolean) as string[]);

    try {
      const result = await saveValidatedDemandBatch(demandData, dryRun, props.materialType);
      setErrorMessages(result.errorMessages);
      const totalUnsavedEntries =
        demandData.reduce((sum, row) => sum + row.kpiEntries.length, 0) - result.savedCount;
      const totalUnsavedRows = demandData.filter((row) =>
        row.kpiEntries.some((e) => e.idx && result.errorMessages[e.idx.toString()]),
      ).length;

      if (result.savedCount > 0 && !dryRun) {
        props.onSave();
      }

      const i18nActionKey = dryRun ? 'validate' : 'save';

      if (totalUnsavedEntries == 0) {
        snackbar.enqueueSnackbar(
          t(`validation_of_demand.upload_modal.${i18nActionKey}.success`, {}),
          {
            variant: 'success',
          },
        );

        if (!dryRun) {
          handleCloseModal();
        }
      } else {
        snackbar.enqueueSnackbar(
          i18nActionKey == 'validate'
            ? t(`validation_of_demand.upload_modal.${i18nActionKey}.invalid_rows`, {
                count: invalidRows.length + totalUnsavedRows,
              })
            : t(`validation_of_demand.upload_modal.${i18nActionKey}.invalid_entries`, {
                count: totalUnsavedEntries,
              }),
          { variant: 'error' },
        );
      }
    } catch (e) {
      const errorMessage = t('validation_of_demand.upload_modal.upload_failed', {
        reason: getErrorMessage(e),
      });
      snackbar.enqueueSnackbar(errorMessage, { variant: 'error' });
    }

    setLoading(false);
  }

  const materialValidationFunction = useCallback(
    (value: string, node: IRowNode) => {
      const localValidation =
        value && props.materialType == 'schaeffler' ? validateMaterialNumber(value) : null;

      const parsingError = node.id && parsingErrors.includes(node.id);

      if (localValidation) {
        return localValidation.join('\n');
      } else if (parsingError) {
        return t('validation_of_demand.upload_modal.error.incomplete_row', {});
      } else {
        const cellIds = Object.keys(node.data)
          .map((_, index) => {
            const idx = node.id != null ? parseInt(node.id) * 100000 + index : undefined;
            if (idx !== undefined) {
              return idx;
            }
          })
          .filter(Boolean) as number[];

        const errorMessagesForEntry = cellIds
          .map((cellId) => errorMessages[cellId])
          .filter(Boolean)
          .flat();
        return errorMessagesForEntry
          ?.map((resultMessage) => resultMessage && errorsFromSAPtoMessage(resultMessage))
          .filter((value, index, self) => self.indexOf(value) === index)
          .join('\n');
      }
    },
    [errorMessages, parsingErrors, props.materialType],
  );

  const columnDefs = useMemo(() => {
    const columnDefs = [
      {
        field: 'materialNumber',
        headerName:
          props.materialType == 'customer'
            ? t('validation_of_demand.upload_modal.customer_material_number', {})
            : t('validation_of_demand.upload_modal.material_number', {}),
        editable: true,
        ...buildValidationProps(materialValidationFunction, true, 'white'),
      },
      ...((kpiBuckets?.map((bucket) => {
        const date = parseISO(bucket.from);
        return {
          key: keyFromDate(date),
          field: keyFromDate(date),
          headerName:
            bucket.type === 'WEEK' || bucket.type == 'PARTIAL_WEEK'
              ? `${getWeekHeader(bucket.from, bucket.type)} \n ${formatDate(date)}`
              : format(date, preferredDateFormatWithoutDay),
          editable: true,
          valueFormatter: numberValueFormatterAgGrid,
          ...buildValidationProps(
            validateForFloat,
            false,
            bucket.type == 'PARTIAL_WEEK' ? demandValidationPartialWeekColor : null,
          ),
        };
      }) as ColDef[]) || []),
      {
        cellRenderer: 'deleteButton',
        minWidth: 68,
        maxWidth: 68,
      },
    ] as ColDef[];

    return columnDefs;
  }, [kpiBuckets, materialValidationFunction, props.materialType]);

  return (
    <Modal open={props.open} onClose={handleCloseModal} maxWidth={false} fullWidth>
      <LoadingSpinnerModal open={loading} />

      <Modal.Headline
        text={`${t('validation_of_demand.upload_modal.grid.title', {})} - ${
          props.customer.customerName
        }`}
        onClose={handleCloseModal}
      >
        <ActionButton
          color={'secondary'}
          name={t('button.reset', {})}
          onClick={() => resetGrid(gridRef)}
        >
          <Close />
        </ActionButton>
        <ActionButton
          color={'secondary'}
          name={t('button.validate', {})}
          onClick={() => saveUpload(true)}
        >
          <Check />
        </ActionButton>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => {
            saveUpload();
          }}
        >
          {t('button.save', {})}
        </Button>
      </Modal.Headline>

      <Modal.SubHeadline />

      <Modal.Body>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledGridSection>
            <AgGridReact
              {...clientSideTableDefaultProps}
              ref={gridRef}
              domLayout={'autoHeight'}
              defaultColDef={{
                suppressMenu: true,
              }}
              onCellEditingStopped={() => {
                ensureEmptyRowAtBottom(gridRef);
              }}
              onRowDataUpdated={() => {
                ensureEmptyRowAtBottom(gridRef);
              }}
              suppressRowHoverHighlight={true}
              suppressRowDeselection={true}
              suppressMultiRangeSelection={false}
              components={{
                deleteButton: DeleteButtonCellRenderer,
              }}
              onGridReady={(e) => {
                e.api.applyTransaction({ add: [] });
              }}
              processDataFromClipboard={(params) => addRowsFromClipboard(gridRef, params.data)}
              tooltipShowDelay={500}
              columnDefs={columnDefs}
            />
          </StyledGridSection>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function keyFromDate(date: Date) {
  return date.toUTCString();
}

function validatedDemandFromRow({
  rowNode: { id, data },
  customerNumber,
  kpiBuckets,
}: {
  rowNode: IRowNode;
  customerNumber: string;
  kpiBuckets: KpiBucket[];
}): WriteKpiData {
  const { materialNumber } = data;

  if (!materialNumber || typeof materialNumber != 'string') {
    throw new Error(`Cound not parse field 'materialNumber' as string.`);
  }

  const parsedData: WriteKpiEntry[] = kpiBuckets
    .map((bucket) => {
      const date = parseISO(bucket.from);
      const valueString = data[keyFromDate(date)];

      let value: number | null = null;
      if (valueString) {
        value = strictlyParseFloat(valueString);

        if (Number.isNaN(value)) {
          throw new Error(`Could not parse value '${value}' as number.`);
        }
      }

      return {
        idx: getCellId(id, data, date),
        fromDate: format(date, 'yyyy-MM-dd'),
        bucketType: bucket.type,
        validatedForecast: value as number,
      } as WriteKpiEntry;
    })
    .filter((entry) => entry.validatedForecast != undefined);

  return {
    ids: id ? [id] : [],
    customerNumber,
    materialNumber,
    kpiEntries: parsedData,
  };
}

function getCellId(rowId: string | undefined, data: any, date: Date): number | undefined {
  return rowId != null
    ? parseInt(rowId) * 100000 + Object.keys(data).indexOf(keyFromDate(date))
    : undefined;
}
