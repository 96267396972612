import { errorsFromSAPtoMessage, ResultMessage } from '../../core/errorhandling';
import { t } from '../../core/i18n/i18n';
import { requestFromApi } from '../../core/requests/httpClient';

import { WriteKpiData, WriteKpiDataResponse } from './model';

export type ValidatedDemandBatchErrorMessages = Record<string, ResultMessage[] | undefined>;

export type ValidatedDemandBatchResult = {
  savedCount: number;
  errorMessages: ValidatedDemandBatchErrorMessages;
};

export type MaterialType = 'schaeffler' | 'customer';

export async function saveValidatedDemandBatch(
  data: WriteKpiData[],
  dryRun: boolean,
  materialType: MaterialType,
): Promise<ValidatedDemandBatchResult> {
  const formData = new FormData();
  const jsonBlob = new Blob([JSON.stringify(data)], { type: 'application/json' });
  formData.append('data', jsonBlob);

  // In order for a multipart to work, the browser needs to automatically deduce the
  // content type and set the correct header with a boundary, hence we need to turn the autoHeader off.
  const resData = (await requestFromApi(
    `demand-validation?dryRun=${dryRun}&useCustomerMaterials=${materialType == 'customer'}`,
    {
      method: 'PATCH',
      body: formData,
    },
    { suppressContentTypeHeader: true },
  )) as WriteKpiDataResponse[];

  const errorMessages: ValidatedDemandBatchErrorMessages = {};

  resData.forEach((entry) => {
    entry.results.forEach((result) => {
      if (
        result.idx !== null &&
        result.idx !== undefined &&
        result.result.messageType != 'SUCCESS'
      ) {
        errorMessages[result.idx.toString()] = [result.result];
      }
    });
  });

  const savedCount = resData
    .flatMap((entry) => entry.results)
    .filter((result) => result.result.messageType == 'SUCCESS').length;

  return {
    savedCount,
    errorMessages,
  };
}

export async function saveValidatedDemandSingleMcc(
  validatedDemandToWrite: WriteKpiData | null,
  errorInputIdentifiers: Set<string>,
  dryRun: boolean,
): Promise<string | null> {
  if (!validatedDemandToWrite) {
    return t('validation_of_demand.error.no_data', {});
  }

  if (errorInputIdentifiers.size > 0) {
    const dates = Array.from(errorInputIdentifiers.values()).join(', ');
    return t(`validation_of_demand.${dryRun ? 'check' : 'save'}.error_specific`, { dates: dates });
  }

  try {
    const body = JSON.stringify(validatedDemandToWrite);
    const result = (await requestFromApi(`demand-validation?dryRun=${dryRun}`, {
      method: 'POST',
      body: body,
    })) as WriteKpiDataResponse;
    if (result.results.every((res) => res.result.messageType == 'SUCCESS')) {
      return null; // no error, success!
    } else {
      const errorDatesAndCauses = result.results
        .map((val) =>
          val.result.messageType == 'ERROR'
            ? `\n ${val.fromDate}: ${errorsFromSAPtoMessage(val.result)}`
            : null,
        )
        .filter((v) => v !== null)
        .join(', ');
      return t(`validation_of_demand.${dryRun ? 'check' : 'save'}.error_specific`, {
        dates: errorDatesAndCauses,
      });
    }
  } catch (e) {
    return t('validation_of_demand.save.error_unspecific', {});
  }
}
